var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new_content"},[_c('div',{staticClass:"cont_tab"},[_c('Table',{attrs:{"columns":_vm.columnsTable,"data":_vm.dataTable,"loading":_vm.loadingTable},scopedSlots:_vm._u([{key:"level",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.level ? row.level.current_data_id : "")+" ")]}},{key:"member",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.member ? row.member.expire_time : "")+" ")]}},{key:"tableSwitch",fn:function(ref){
var row = ref.row;
return [_c('i-switch',{attrs:{"true-value":0,"false-value":1,"size":"large"},on:{"on-change":function($event){return _vm.changeSwitch(row)}},model:{value:(row.it_disabled),callback:function ($$v) {_vm.$set(row, "it_disabled", $$v)},expression:"row.it_disabled"}},[_c('span',{attrs:{"slot":"open"},slot:"open"},[_vm._v("开启")]),_c('span',{attrs:{"slot":"close"},slot:"close"},[_vm._v("关闭")])])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{staticStyle:{"color":"red","background":"unset"},attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteModal(row)}}},[_vm._v("删除")])]}}])}),_c('br'),_c('Page',{staticStyle:{"text-align":"right"},attrs:{"total":_vm.total,"current":_vm.pageForm.page,"show-total":"","show-elevator":""},on:{"on-change":_vm.pageChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }