<template>
    <div class="new_content">
        <div class="cont_tab">
            <Table :columns="columnsTable" :data="dataTable" :loading="loadingTable" >
                
                <template slot-scope="{ row }" slot="level">
                   {{row.level ?  row.level.current_data_id : ""}}
                </template>
                <template slot-scope="{ row }" slot="member">
                   {{row.member ?  row.member.expire_time : ""}}
                </template>
                <template slot-scope="{ row }" slot="tableSwitch">                
                    <i-switch   :true-value="0" :false-value="1" size="large" @on-change="changeSwitch(row)" v-model="row.it_disabled">
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </template>      
                       
                <template slot-scope="{ row }" slot="action">
                    <Button style="color:red;background: unset;" type="text" @click="deleteModal(row)" >删除</Button>
                </template>
            </Table>
            <br />
            <Page :total="total" :current="pageForm.page" @on-change="pageChange" show-total show-elevator style="text-align:right" />   
        </div>
        
    </div>
</template>

<script>
    var _this;
    import { getUserList, modifyUserStatus, deleteUser } from "@/api/index";
    export default {
        data(){
            return{
                loadingTable:false,  //表格loading
                dataTable: [],    //表格data
                //表格columns
                columnsTable: [
                    // {type: 'selection',width: 60,align: 'center'},
                    {title: '#',width: 60,  type: 'index',align: 'center'},
                    {title: 'ID',key: 'id',align: 'center', width: 80},
                    {title: '昵称',key: 'nickname',align: 'center', width: 130},
                    {title: '微信号',key: 'wx',align: 'center', width: 130},       
                    {title: '手机号',key: 'mobile',align: 'center', width: 130},                                 
                    {title: '总付款金额',key: 'pay_money',align: 'center', width: 170},                               
                    {title: '最后付款时间',key: 'pay_time',align: 'center', width: 200},                           
                    {title: '会员时效',slot: 'member',align: 'center', width: 200},                             
                    {title: '当前关卡',slot: 'level',align: 'center', width: 130},                             
                    {title: '渠道号',key: 'channel_number',align: 'center', width: 130},                           
                    {title: '状态',slot: 'tableSwitch',align: 'center', width: 130},                                            
                    {title: '操作',slot: 'action',width: 220,fixed: 'right',align: 'center',}
                ],
                total: 0,   //表格数据总数
                //表格分页form
                pageForm: {
                    page:1,
                    limit:30
                }
            }
        },
        created(){
            _this = this;
            this.getList()         
        },
        computed: {            
        },
        methods:{
            getList(){
                let data = {}
                data = {...this.pageForm }
                this.loadingTable = true  //数据加载
                getUserList(data).then(res=>{
                    this.loadingTable = false
                    this.total = res.data.total
                    this.dataTable = res.data.data
                }).catch(err=>{                    
                    // this.$Message.error(err.msg)
                    console.log('表格err',err)
                })
            },
            changeSwitch(v){
                modifyUserStatus({id:v.id}).then(res=>{
                    this.$Message.success(res.msg)
                }).catch(err=>{                    
                    console.log('表格err',err)
                })
            },
            deleteModal(v){
                this.$Modal.confirm({
                    title: '提示',
                    content: '<p>是否确认删除该用户：'+v.nickname+'</p>',
                    onOk: () => {
                        this.deleteUser(v.id);
                    }
                })
            },
            deleteUser(v){
                deleteUser({id:v}).then(res=>{
                    this.$Message.success(res.msg)
                    this.getList();
                }).catch(err=>{                    
                    console.log('表格err',err)
                })
            },
            //分页切换                
            pageChange(e){
                this.pageForm.page = e
                this.getList()
            }
        }
    }
</script>  

<style scoped>
.new_content {
    /* width: 100%; */
    /* height: calc(100vh - 104px); */
    overflow: auto;
    /* padding: 20px 40px; */
    padding: 24px;
    /* background: #FFF; */
    border-radius: 8px;
    box-shadow: #F4F0E8 0px 1px 2px 0px;
}
.cont_tab {
    padding: 0px 0;
}
.flex_sta_cen {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.search_box {
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 20px;
}
.tab_tab_text {
    /* width: 0px; */
    padding: 4px 16px;
    border-radius: 4px;
    border: 1px solid #002FA7;
    cursor: pointer;
    color: #002FA7;
}
.table_img_box {
    /* width: 60px;
    height: 60px; */
}
.table_img_box img {
    width: 80px;
    height: 80px;
}
/* 上传样式开始 */
.upload_img{
    cursor: pointer;
    width: 80px;
    height: 80px;
    border: 1px solid #eee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.upload_img_txt{
    margin-top: -10px;
    font-size: 12px;
    color: #999;
}
.absolu_close:hover{
    opacity: 1;
}
.absolu_close {
    cursor: pointer;
    position: absolute;
    top: 0;
    opacity: 0;
    left: 0;
    width: 80px;
    height: 80px;
    background: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.viewimg{
    position: relative;
    width: 80px;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
}
.viewimg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* 上传样式结束 */

</style>